<template>
  <div class="template-serve">
    <b-overlay :show="show" variant="transparent">
      <div class="content">
        <div class="left-area">
          <div class="carousel-area">
            <b-carousel
              id="carousel-fade"
              style="text-shadow: 0px 0px 2px #000"
              indicators
              img-width="784"
              img-height="480"
              controls
              v-model="slide"
              label-next=""
              label-prev=""
            >
              <b-carousel-slide
                v-for="(file, index) in images"
                :key="index"
                :img-src="file"
              ></b-carousel-slide>
            </b-carousel>
            <div class="course">
              <div
                class="course-item"
                v-for="(item, index) in images"
                :key="index"
                @click="selectImg(index)"
              >
                <img :src="item" style="width: auto; height: 100px" />
              </div>
            </div>
          </div>
          <div class="title">{{ detail.title }}</div>
          <div class="sub-area">
            <div class="sub-title">· 术前诊断</div>
            <div class="sub-desc">
              {{ detail.surgery_before_text }}
            </div>
            <div class="sub-images">
              <div class="item" v-for="(item, index) in before" :key="index">
                <img src="item" alt="图片加载失败" />
              </div>
            </div>
          </div>
          <div class="sub-area">
            <div class="sub-title">· 方案设计</div>
            <div class="sub-desc">
              {{ detail.design_text }}
            </div>
            <div class="sub-images">
              <div class="item" v-for="(item, index) in design" :key="index">
                <img :src="item" alt="图片加载失败" />
              </div>
            </div>
          </div>
          <div class="sub-area">
            <div class="sub-title">· 手术总结</div>
            <div class="sub-desc">
              {{ detail.surgery_summary }}
            </div>
            <div class="sub-images">
              <div class="item" v-for="(item, index) in summary" :key="index">
                <img :src="item" alt="图片加载失败" />
              </div>
            </div>
          </div>
          <div class="of-serve" @click="toShop(detail.goods_info.product_id)">
            <div class="of-serve-title">案例所属服务</div>
            <img
              :src="detail.goods_info ? $host + detail.goods_info.image_id : ''"
              class="goods-img"
            />
            <div class="of-serve-info" style="width: 560px">
              <div class="of-serve-info-title">
                {{ detail.goods_info ? detail.goods_info.name : "" }}
              </div>
              <div class="of-serve-info-price">
                ￥ {{ detail.goods_info ? detail.goods_info.price : 0 }}
              </div>
            </div>
          </div>
        </div>
        <div class="right-area">
          <div class="article-card">
            <div class="article-card-title">案例作者</div>
            <div class="article-card-info">
              <div style="width: 80px; text-align: center">
                <img
                  :src="$host + userInfo.avatar"
                  style="width: 60px; height: 60px; border-radius: 50%"
                />
              </div>
              <div style="width: 120px">
                <div class="article-card-nickname">{{ userInfo.nickname }}</div>
                <div class="article-card-desc">
                  {{ userInfo.position_name }}
                </div>
              </div>
            </div>
            <div class="selects">
              <div class="options">好评： {{ userInfo.favorable_rate }}%</div>
              <div class="options">服务人次： {{ userInfo.user_count }}</div>
              <div class="options">执业年限： {{ userInfo.workingtime }}</div>
            </div>
            <div class="btm">
              <div class="gzbtn" @click="guanzhu">
                {{ userInfo.is_friend === "false" ? "关注" : "已关注" }}
              </div>
            </div>
          </div>

          <div class="recome-area">
            <div class="title">相关案例</div>
            <div
              class="item"
              v-for="(item, index) in goodsList"
              :key="index"
              v-show="item.id != $route.query.id"
              @click="openURL(item.id)"
            >
              <img :src="item.src" class="avatar" />
              <div class="info">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="time">
                  {{ $timeFormat(item.createtime, "yy-mm-dd h:MM") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      slide: 0,
      images: [],
      detail: {},
      userInfo: {},
      before: [],
      design: [],
      summary: [],
      goodsList: [],
      show: false
    };
  },
  mounted() {
    this.getDetail();
    this.getDoctor();
    this.$nextTick(() => {
      this.getServes();
    });
  },
  computed: {
    pageid() {
      return this.$route.query.id;
    },
  },
  watch: {
    pageid: function () {
      this.getDetail();
    },
  },
  methods: {
    selectImg(i) {
      this.slide = i;
    },
    getDetail() {
      this.$http.casestudyinfo({ id: this.$route.query.id }).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.detail = data;
          this.images = data.images.split(",");
          this.before = data.surgery_before_image.split(",");
          let design = data.design_images.split(",");
          this.design = design;

          let end = data.surgery_summary_images.split(",");
          this.summary = end;
        } else {
          this.$emit("showToast", msg);
        }
      });
    },
    getDoctor() {
      this.$http.doctorinfo({ id: this.$route.query.doctor }).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.userInfo = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    guanzhu() {},
    toShop(id) {
      this.$router.push({
        path: "/goods_info?id=" + id,
      });
    },
    async getServes() {
      this.$http
        .casestudylist({
          doctors_id: this.$route.query.doctor,
          page: 1,
          limit: 5,
        })
        .then((res) => {
          let { data, code, msg } = res;
          if (code == 1) {
            data.items.forEach((item) => {
              item.src = this.$host + item.images.split(",")[0];
            });
            this.goodsList = data.items;
          } else {
            this.$store.commit("showToast", msg);
          }
        });
    },
    openURL(id) {
      this.show = true
      setTimeout(() => {
        this.show = false
      }, 500)
      let url = "/doctor_serve?id=" + id + "&doctor=" + this.$route.query.doctor;
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./serve.scss";
</style>
